import Head from 'next/head';
import styles from './Layout.module.css';

export default function LayoutFont({ children }: { children: any }) {
  return (
    <>
      <Head>
        <link
          rel="shortcut icon"
          href="https://storage.googleapis.com/goyolo-production/favicon.ico"
        />
      </Head>
      <main className={styles.global}>{children}</main>
    </>
  );
}
